<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner pt-6 md:pt-12">
      <div class="mb-14 sm:mb-20">
        <h1 class="text-royalblue-900 mb-10">Our History</h1>
        <div class="flex flex-wrap -m-4">
          <div class="w-full lg:w-1/2 p-4">
            <p>
              Balance Neuro Physiotherapy was initiated in September 2013 by
              Diane Ormsby. This was in response to the growing need for
              individuals' to continue their rehabilitation; often following, or
              waiting for, NHS rehab or changing needs as part of an ongoing
              condition.
            </p>
            <p>
              In January 2018 Diane Ormsby and Gemma Brown joined forces,
              combining both of their independent practices to become a
              cooperative organisation of physiotherapists specialising in the
              field of neurology. We are an expanding network of hardworking
              physiotherapists who share a commitment to providing contemporary
              and evidence based therapy.
            </p>
          </div>
          <div class="w-full lg:w-1/2 p-4">
            <p>We shall tailor our treatment to focus on agreed goals.</p>
            <p>
              We have physiotherapy/Rehab assistants working alongside the
              physiotherapists to practise and repeat activity and exercise
              programmes.These sessions are regularly reviewed and progressed as
              required. This helps to increase the frequency of treatments while
              keeping your costs under control.
            </p>
            <p>
              Every qualified member of staff is registered to practice with the
              HCPC and a member of the Chartered Society of Physiotherapy. We
              are all currently DBS checked.
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap -m-4">
        <feature-3-cols
          :icon="['far', 'user']"
          title="Meet the team"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Meet The Team"
        />
        <feature-3-cols
          :icon="['far', 'heart']"
          title="Our commitment to you"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Our Commitment To You"
        />
        <feature-3-cols
          :icon="['far', 'map']"
          title="Areas covered"
          summary="Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine."
          page="Areas Covered"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Feature3Cols from "../components/Feature3Cols.vue";

export default {
  name: "About",
  components: {
    "feature-3-cols": Feature3Cols,
  },
};
</script>

<template>
  <div class="p-4 md:w-1/3">
    <div class="flex rounded-lg h-full bg-royalblue-200 p-8 flex-col">
      <div class="flex flex-col md:items-center mb-3">
        <font-awesome-icon
          :icon="icon"
          class="text-4xl text-royalblue-400 mb-3"
        />
        <h5 class="text-white leading-relaxed mb-4">{{ title }}</h5>
      </div>
      <div class="flex flex-grow justify-center">
        <!-- <p class="text-white mb-6">
          {{ summary }}
        </p> -->
        <base-btn :url="page">Learn More</base-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "../components/ui/BaseBtn.vue";

export default {
  components: {
    "base-btn": BaseBtn,
  },
  props: {
    icon: {
      Type: String,
      required: false,
    },
    title: {
      Type: String,
      required: true,
    },
    summary: {
      Type: String,
      required: true,
    },
    page: {
      Type: String,
      required: true,
    },
  },
};
</script>
